// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dataset-js": () => import("./../../../src/pages/dataset.js" /* webpackChunkName: "component---src-pages-dataset-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oldindex-js": () => import("./../../../src/pages/oldindex.js" /* webpackChunkName: "component---src-pages-oldindex-js" */),
  "component---src-pages-person-js": () => import("./../../../src/pages/person.js" /* webpackChunkName: "component---src-pages-person-js" */),
  "component---src-pages-political-party-js": () => import("./../../../src/pages/political-party.js" /* webpackChunkName: "component---src-pages-political-party-js" */),
  "component---src-pages-search-people-js": () => import("./../../../src/pages/search-people.js" /* webpackChunkName: "component---src-pages-search-people-js" */)
}

